<template>
    <default-layout>
        <div class="spainhn-solid-red-background d-flex" style="min-height: 5vh; width:100%">
            <div class="d-flex ps-5 my-auto container">
                <p class="my-auto pb-0 mb-0 spainhn-small-text" role="button" @click="goToHome">Spain Heritage Network >> </p>
                <p class="my-auto pb-0 mb-0 ms-2 spainhn-small-text">{{$t('default.FOOTER_ACCESS')}} </p>
            </div>
        </div>
        <div class="container-fluid px-md-1 px-lg-4 px-1 mx-auto" :style="{padding:'0 0', backgroundImage:`url(${require('@/assets/home-bg.jpg')})`, backgroundSize:'cover', 'background-repeat': 'no-repeat','background-position': 'right', 'min-height':'80vh'}">
            <div class="container-fluid mb-5">
                <div class="row text-start margen-estandard pt-5">

                    <div class="d-flex flex-column  mx-auto pt-3 col-12 col-md-6 mb-3 pb-4 px-3" >
                        <div :style="{backgroundColor:'rgba(0,0,0,0.3)'}" class="py-4 px-3 px-sm-0">
                            <div class="row pt-2">
                                <div class="col-12 col-md-10 mx-auto">
                                    <p class="text-center spainhn-subtitle-2-black">{{$t('default.RESET_PASSWORD_TITLE')}}</p>
                                </div>
                            </div> 

                            <div class="row">
                                <div class="col-md-10 col-12 mx-auto">
                                    <b-form>
                                        <b-form-input name="username" :state="emailState" v-model="email" :placeholder="$t('default.ACCESS_EMAIL_PLACEHOLDER')" class="my-2" type="email"/>
                                        <div class="w-100">
                                            <mdb-btn color="indigo" class="spainhn-button w-100 py-2 spainhn-text mt-3" style="font-size:16pt" block @click="passwordReset">{{ $t('default.RESET_PASSWORD') }} </mdb-btn>
                                            <mdb-btn color="indigo" class="spainhn-button w-100 py-2 spainhn-text mt-3" style="font-size:16pt" block @click="goToLogin">{{ $t('default.RESET_PASSWORD_CANCEL') }} </mdb-btn>
                                        </div>
                                        
                                    </b-form>
                                </div>
                            </div>    

                        </div>                        
                    </div>

                </div>
            </div>

            <b-modal ref="error-acceso"
                title="Error" hide-header hide-header-close>
                <div class="d-flex">
                    <p class="text-center spainhn-subtitle-2-black">{{errorDeAcceso}}</p>
                </div>
            </b-modal>
        </div>
        <sucess-alert :show="okStatus" :mensaje="okMensaje" @close="cerrarConfirm"></sucess-alert>

    </default-layout>
</template>

<script>

import DefaultLayout from '../layouts/DefaultLayout.vue'
import ApiService from '../services/ApiService';
import { mapActions } from 'vuex';
import SucessAlert from '../components/SuccessAlert.vue'

export default {
    components:{
        DefaultLayout, 
        SucessAlert
    },
    data(){
        return{
            email: null,
            password: null,
            errorDeAcceso: null,
            emailState: null,
            passwordState: null,
            okStatus: false,
            okMensaje: null
        }
    },
    methods:{
        ...mapActions([
            'fetchAccessToken'
        ]),
        goToLogin(){
            let $vm = this;
            $vm.$router.push('/clientes/login')
        },
        goToHome(){
            let $vm = this;
            $vm.$router.push('/')
        },
        cerrarConfirm(){
            let $vm = this;
            $vm.okStatus = false;
        },

        /**
         * Solicita acceso para el usuario
         */
        passwordReset(){
            let $vm = this;
            // Primero revisamos que se haya llenado correctamente el password
            // y el usuario
            if( $vm.email != null ){
                ApiService.requestReset({ 'email': $vm.email})
                .then( res => {
                    // La respuesta con status 200 indica que las credenciales de acceso son correctas
                    // La respuesta incluye el token de acceso
                    console.log(res);
                    $vm.okStatus = true;
                    $vm.okMensaje = "Se envió un correo con instrucciones para reestablecer su contraseña"
                })
                .catch( err => {
                    // Si la respuesta es incorrecta, entonces se muestra el mensaje de error
                    if( err.response.status == 401 ){
                        if( err.response.data.error == "usuario" ){
                            $vm.errorDeAcceso = "No existe un usuario registrado con este correo";
                            $vm.$refs['error-acceso'].show();
                        }
                        else if( err.response.data.error == "credentials" ){
                            console.log("Error de credenciales")
                            $vm.errorDeAcceso = "Por favor, revise su usuario o contraseña";
                            $vm.$refs['error-acceso'].show();
                        }
                    }
                })
            }
            // En caso contrario, marcamos en donde está el error
            else {
                if( $vm.username == null || !$vm.username.includes("@") || $vm.username == "" ){
                    $vm.emailState = false;
                }
                else{
                    $vm.emailState = true;
                }
                if( $vm.password == null || $vm.password == "" ){
                    $vm.passwordState = false;
                }
                else{
                    $vm.passwordState = null;
                }
            }
        }
    },
    setup() {
        
    },
    created(){
       
    },
    computed: {

    }
}
</script>


<style scoped>
    .text-columns{
        column-count: 3;
        /* height: 50vh; */
    }
    .disable-selector{
        color:rgba(225,225,225);
    }
    .slide-show-container{
        position: relative;
        width: 100%;
        margin: auto;
    }
    .spainhn-slides{
        display: none;
    }

    /* Next & previous buttons */
    .prev, .next {
    cursor: pointer;
    position: absolute;
    top: 50%;
    width: auto;
    margin-top: -22px;
    padding: 16px;
    color: white;
    font-weight: bold;
    font-size: 18px;
    transition: 0.6s ease;
    border-radius: 0 3px 3px 0;
    user-select: none;
    }

    /* Position the "next button" to the right */
    .next {
    right: 0;
    border-radius: 3px 0 0 3px;
    }
    
    /* Fading animation */
    .fade {
        animation-name: fade;
        animation-duration: 10s;
    }

    @keyframes fade {
        from {opacity: .4}
        to {opacity: 1}
    }

        @media (min-width: 768px) {
        .text-columns{
            column-count: 3;
            /* height: 50vh; */
        }
    }

    @media (max-width: 768px){.text-columns{
        column-count: 2;
        /* height: 50vh; */
    }}

    @media (max-width: 424px){
        .text-columns{
        column-count: 1;
        /* height: 50vh; */
    }}
</style>